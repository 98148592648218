<!-- =========================================================================================
    File Name: Profile.vue
    Description: Profile Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>

    <div id="profile-page">

        <!-- PROFILE HEADER -->
        <div class="profile-header">
            <div class="relative">
                <div class="cover-container rounded-t-lg">
                    <img :src="require(`@/assets/images/profile/user-uploads/slide1.jpg`)" loading="lazy" alt="user-profile-cover" class="responsive block">
                </div>
                <div class="profile-img-container pointer-events-none">
                    <div>
                        <vs-avatar class="user-profile-img" :src="require(`@/assets/images/logo/logo.png`)" size="95px" />
                    </div>
                    <div class="profile-actions pointer-events-auto flex">
                        <vs-button icon-pack="feather" radius icon="icon-edit-2"></vs-button>
                        <vs-button icon-pack="feather" radius icon="icon-settings" class="ml-2 lg:ml-4"></vs-button>
                    </div>
                </div>
            </div>
            <div class="flex items-center justify-end flex-wrap profile-header-nav p-6">

                <div class="block sm:hidden">
                    <feather-icon @click="isNavOpen = !isNavOpen" icon="AlignJustifyIcon" v-show="!isNavOpen" class="vx-navbar-toggler" />
                    <feather-icon icon="XIcon" v-show="isNavOpen" @click="isNavOpen = !isNavOpen" class="vx-navbar-toggler" />
                </div>
                <div :class="isNavOpen ? 'block': 'hidden'" class="w-full flex-grow sm:flex sm:items-center sm:w-auto">
                    <div class="text-sm sm:flex-grow">
                        <ul class="sm:flex justify-around mt-8 w-full md:mt-0 md:ml-auto md:w-3/4">
                        <!--
                            <li class="p-2 sm:p-0"><router-link to="/pages/profile">Timeline</router-link></li>
                            <li class="p-2 sm:p-0"><router-link to="/pages/profile">Fotoğraflar</router-link></li>
                            <li class="p-2 sm:p-0"><router-link to="/pages/profile">Referanslar</router-link></li>
                            <li class="p-2 sm:p-0"><router-link to="/pages/profile">Projeler</router-link></li>
                            <li class="p-2 sm:p-0"><router-link to="/pages/profile">Etkinlikler</router-link></li>
                        -->
                        </ul>
                    </div>
                </div>
            </div>
            <!-- <vx-navbar> -->
            <!-- </vx-navbar> -->
        </div>

        <!-- COL AREA -->
        <div class="vx-row">
            <!-- COL 1 -->
            <div class="vx-col w-full lg:w-1/4">
                <!-- ABOUT CARD -->
                <vx-card title="Hakkımızda" class="mt-base">
                    <!-- ACTION SLOT -->
                    <template slot="actions">
                        <feather-icon icon="MoreHorizontalIcon"></feather-icon>
                    </template>

                    <!-- USER BIO -->
                    <div class="user-bio">
                        <p>Bilişim sektörüne 2011 yılında Teknolojide Güven Sloganıyla adım atan  Ceddan Bilişim Teknolojileri. son teknolojiyi, rekabetçi fiyatla ve eksiksiz destek hizmetiyle Türk BT pazarına sunmak amacıyla kurulmuştur. Hem tedarikçi firmalara hem de kurumsal ve bireysel müşterilerine yalın ve direkt süreçlerle ulaşarak, sürekli maliyet avantajı oluşturmayı hedeflemektedir. Böylelikle, kaliteden ödün vermeksizin, en yeni teknolojiyi yansıtan ürün ve çözümlerini, en uygun fiyatla pazara sunabilmektedir. En zor projelerde bile müşterilerinin yanında olan Ceddan Bilişim Teknolojileri. , sistem entegrasyon ve danışmanlık hizmetleri ile teknolojide güven sloganı çerçevesinde eksiksiz, tam çözüm sağlamaktadır.</p>
                    </div>

                    <!-- OTEHR DATA -->
                    <div class="mt-5">
                        <h6>Kurulum Yılı:</h6>
                        <p>2011</p>
                    </div>

                    <div class="mt-5">
                        <h6>Adres:</h6>
                        <p>Aydınlıkevler Mah Çağdaş Sokak No:43/A Altındağ , Ankara / Türkiye</p>
                    </div>

                    <div class="mt-5">
                        <h6>Email:</h6>
                        <p>ceddan@ceddan.com</p>
                    </div>

                    <div class="mt-5">
                        <h6>Website:</h6>
                        <p>www.ceddan.com</p>
                    </div>

                    <div class="social-links flex mt-4">
                        <a rel="nofollow" href="https://www.facebook.com/ceddanbilisim" target="_blank" ><feather-icon svgClasses="h-7 w-7 cursor-pointer bg-primary p-1 text-white rounded" class="mr-2" icon="FacebookIcon" ></feather-icon></a>
                        <a rel="nofollow" href="https://www.twitter.com/ceddan" target="_blank"><feather-icon svgClasses="h-7 w-7 cursor-pointer bg-primary p-1 text-white rounded" class="mr-2" icon="TwitterIcon" ></feather-icon></a>
                        <a rel="nofollow" href="https://www.instagram.com/ceddanbilisim" target="_blank"><feather-icon svgClasses="h-7 w-7 cursor-pointer bg-primary p-1 text-white rounded" class="mr-2" icon="InstagramIcon" ></feather-icon></a>
                    </div>
                </vx-card>

                <!-- PAGES SUGGESTION -->
                <vx-card title="Son Referanslar" class="mt-base">
                    <ul class="page-suggestions-list">
                        <li class="page-suggestion flex items-center mb-4" v-for="page in suggestedPages" :key="page.index">
                            <div class="mr-3"><vs-avatar class="m-0" :src="require(`@/assets/images/profile/pages/${page.img}`)" size="35px" /></div>
                            <div class="leading-tight">
                                <p class="font-medium">{{ page.title | capitalize }}</p>
                                <span class="text-xs">{{ page.type | capitalize }}</span>
                            </div>
                            <div class="ml-auto">
                                <div class="flex">
                                    <feather-icon icon="StarIcon" svgClasses="h-4 w-4" class="mr-2 cursor-pointer"></feather-icon>
                                </div>
                                <!-- <span class="flex bg-primary rounded p-2 text-white"><feather-icon icon="UserPlusIcon" svgClasses="w-4 h-4"></feather-icon></span> -->
                            </div>
                        </li>
                    </ul>
                </vx-card>

                
            </div>

            <!-- COL 2 -->
            <div class="vx-col w-full lg:w-1/2">
                <vx-card class="mt-base" v-for="(post, index) in userPosts" :key="index">
                    <div>
                        <!-- POST HEADER -->
                        <div class="post-header flex justify-between mb-4">
                            <div class="flex items-center">
                                <div>
                                    <vs-avatar class="m-0" :src="require(`@/assets/images/profile/user-uploads/${userLatestPhotos[0]}`)" size="45px"></vs-avatar>
                                </div>
                                <div class="ml-4">
                                    <h6>{{ post.author }}</h6>
                                    <small>{{ post.time | date(true) }} at {{ post.time | time }}</small>
                                </div>
                            </div>
                            <div class="flex">
                                <feather-icon class="ml-4" icon="HeartIcon" :svgClasses="{'text-danger fill-current stroke-current': post.isLiked}"></feather-icon>
                            </div>
                        </div>

                        <!-- POST CONTENT -->
                        <div class="post-content">
                            <p>{{ post.text }}</p>
                        </div>
                        <div class="post-media-container mb-6 mt-4">
                            <ul class="flex post-media-list">
                                <li class="post-media m-1 w-full" v-for="(media, mediaIdex) in post.media.slice(0, 2)" :key="mediaIdex">
                                    <img class="responsive rounded" :src="require(`@/assets/images/profile/post-media/${media.img}`)" loading="lazy" alt="user-upload" v-if="mediaType(media) == 'image'">
                                    <video-player ref="player" class="media-video-player" :options="playerOptions(media)" v-else-if="mediaType(media) == 'video'" />
                                    <span class="text-lg text-primary" v-else>Unknown Media Type</span>
                                </li>
                            </ul>
                            <span class="flex justify-end" v-if="post.media.length > 2">
                                <a class="inline-flex items-center text-sm" href=""><span>View All</span> <feather-icon icon="ChevronsRightIcon" svgClasses="h-4 w-4"></feather-icon></a>
                            </span>
                        </div>

                        <!-- POST ACTION DATA -->
                        <div>
                            <div class="flex justify-between">
                                <div class="flex items-center">
                                    <div class="flex items-center"><feather-icon class="mr-2" icon="HeartIcon" svgClasses="h-5 w-5"></feather-icon> <span>{{ post.likes }}</span></div>
                                    <ul class="users-liked user-list ml-3 sm:ml-6">
                                        <li v-for="(user, userIndex) in post.usersLiked" :key="userIndex">
                                            <vx-tooltip :text="user.name" position="bottom">
                                                <vs-avatar :src="require(`@/assets/images/portrait/small/${user.img}`)" size="30px" class="border-2 border-white border-solid -m-1"></vs-avatar>
                                            </vx-tooltip>
                                        </li>
                                    </ul>
                                    <small class="ml-2">+{{ post.likes - 5 }} more</small>
                                </div>
                                <div class="flex items-center"><feather-icon class="mr-2" icon="MessageSquareIcon" svgClasses="h-5 w-5"></feather-icon> <span>{{ post.comments }}</span></div>
                            </div>
                            <div class="comments-container mt-4">
                                <ul class="user-comments-list">
                                    <li v-for="(commentedUser, commentIndex) in post.usersCommented.slice(0, 2)" :key="commentIndex" class="commented-user flex items-center mb-4">
                                        <div class="mr-3"><vs-avatar class="m-0" :src="require(`@/assets/images/portrait/small/${commentedUser.img}`)" size="30px" /></div>
                                        <div class="leading-tight">
                                            <p class="font-medium">{{ commentedUser.author }}</p>
                                            <span class="text-xs">{{ commentedUser.comment }}</span>
                                        </div>
                                        <div class="ml-auto">
                                            <div class="flex">
                                                <feather-icon icon="HeartIcon" svgClasses="h-4 w-4" class="mr-2 cursor-pointer"></feather-icon>
                                                <feather-icon icon="MessageSquareIcon" svgClasses="h-4 w-4" class="cursor-pointer"></feather-icon>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <span class="flex justify-end" v-if="post.usersCommented.length > 2">
                                    <a class="inline-flex items-center text-sm" href=""><span>View All</span> <feather-icon icon="ChevronsRightIcon" svgClasses="h-4 w-4"></feather-icon></a>
                                </span>
                            </div>
                            <div class="post-comment">
                                <vs-textarea label="Yorum Ekle" class="mb-2" v-model="post.commentbox" />
                                <vs-button size="small">Yorum Gönder</vs-button>
                            </div>
                        </div>
                    </div>
                </vx-card>
            </div>

            <!-- COL 3 -->
            <div class="vx-col w-full lg:w-1/4">

                <!-- LATEST PHOTOS -->
                <vx-card title="Son Fotoğraflar" class="mt-base">
                    <div class="vx-row pt-2">
                        <div class="vx-col w-1/2 sm:w-1/2 md:w-1/3 xl:1/4" v-for="(img, index) in userLatestPhotos" :key="index">
                            <img :src="require(`@/assets/images/profile/user-uploads/${img}`)" loading="lazy" alt="latest-upload" class="rounded mb-4 user-latest-image responsive">
                        </div>
                    </div>
                </vx-card>

                <vx-card title="Personellerimiz" class="mt-base">
                    <!-- ACTION SLOT -->
                    <template slot="actions">
                        <feather-icon icon="MoreHorizontalIcon"></feather-icon>
                    </template>

                    <!-- FRIENDS LIST -->
                    <ul class="friend-suggesions-list">
                        <li class="friend-suggestion flex items-center mb-4" v-for="(friend, index) in suggestedFriends" :key="index">
                            <div class="mr-3"><vs-avatar class="m-0" :src="require(`@/assets/images/portrait/small/${friend.avatar}`)" size="35px" /></div>
                            <div class="leading-tight">
                                <p class="font-medium">{{ friend.name }}</p>
                                <span class="text-xs">{{ friend.job }}</span>
                            </div>
                            <div class="ml-auto cursor-pointer">
                                <vs-button icon-pack="feather" icon="icon-user-plus" />
                            </div>
                        </li>
                    </ul>
                    <template slot="footer">
                    <vs-button icon-pack="feather" icon="icon-plus" class="w-full">Daha Fazla...</vs-button>
                    </template>
                </vx-card>

                

            </div>
        </div>

        <div class="vx-row">
            <div class="vx-col w-full">
                <div class="flex justify-center mt-base">
                    <vs-button id="button-load-more-posts" class="vs-con-loading__container" @click="loadContent">Load More</vs-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { videoPlayer } from 'vue-video-player'
import 'video.js/dist/video-js.css'

export default {
    data() {
        return {
            isNavOpen: false,
            userPoll: '',
            userInfo: {
                profileImg: 'user-13.jpg',
            },
            mediaExtensions: { img: ['jpg', 'jpeg', 'png', 'bmp', 'gif', 'exif', 'tiff'], video: ['avi', 'flv', 'wmv', 'mov', 'mp4', '3gp'] },
            suggestedFriends: [
                { name: 'Ömer Faruk DEMİRYÜREK', avatar: 'avatar-s-1.png', mutualFriends: 6, job:'Genel' },
                { name: 'Yusuf TAVŞAN', avatar: 'avatar-s-21.png', mutualFriends: 2, job: 'Eğitim Destek' },
                { name: 'Kadir ÇAKIR', avatar: 'avatar-s-23.png', mutualFriends: 1 ,job: 'Yazılım Geliştirme'},
                
            ],
            userCoverImg: 'cover.jpg',
            userLatestPhotos: ['avatar-s-1.png','avatar-s-3.png','avatar-s-21.png','avatar-s-15.png','avatar-s-23.png'],
            userPosts: [{
                    author: 'Ömer Faruk DEMİRYÜREK',
                    time: 'Mon Dec 12 2011 07:46:05 GMT+3000 (GMT)',
                    isLiked: true,
                    text: 'Kurumsal bilgi işlem ve iletişim sistemlerindeki (sunucular, masaüstü ve taşınabilir/kablosuz bilgisayarlar ile bunları birbirlerine bağlayan ağlar) başarınız, bu çözümleri belirlemek ve kullanmak için deneyimli bir teknoloji uzmanı ile birlikte çalışmanıza bağlıdır. Hedeflerinizi anlayacak ve ihtiyaçlarınızı güvenilir ve ekonomik bir şekilde karşılayabilecek, gerekli eğitime, bilgiye ve deneyime sahip kişilere ihtiyacınız vardır.',
                    media: [{ img: '12.jpg' }],
                    likes: 145,
                    comments: 77,
                    usersLiked: [
                        { name: 'Trina Lynes', img: 'avatar-s-1.png' },
                        { name: 'Lilian Nenez', img: 'avatar-s-2.png' },
                        { name: 'Alberto Glotzbach', img: 'avatar-s-3.png' },
                        { name: 'George Nordick', img: 'avatar-s-4.png' },
                        { name: 'Vennie Mostowy', img: 'avatar-s-5.png' },
                    ],
                    commentbox: '',
                    usersCommented: [
                        { comment: 'İşin Gücün Rast Gelsin', author: 'Recep Atabay', img: 'avatar-s-3.png', time: 'Mon Dec 10 2011 08:56:05 GMT+0000 (GMT)' },
                        { comment: 'Yolun Açık Olsun', author: 'Yusuf TAVŞAN', img: 'avatar-s-21.png', time: 'Mon Dec 10 2011 08:55:00 GMT+0000 (GMT)' },
                    ]
                },
                {
                    author: 'Ömer Faruk DEMİRYÜREK',
                    time: 'Mon Dec 11 2011 08:12:05 GMT+0000 (GMT)',
                    isLiked: false,
                    text: 'HP, MicroSoft, EMC, CA, Symantec başta olmak üzere, kendi alanında dünya devi olan Şirketlerle olan iş ortaklığı ve müşteri memnuniyeti odaklı kalite anlayışıyla, her yapı ve ölçekteki kurumsal müşterilere, her türlü BT ürün ve çözümünü sunmaktadır.',
                    media: [{ img: 'bilgisayar-markalari.jpg' }],
                    likes: 276,
                    comments: 105,
                    usersLiked: [
                        { name: 'Lai Lewandowski', img: 'avatar-s-6.png' },
                        { name: 'Elicia Rieske', img: 'avatar-s-7.png' },
                        { name: 'Darcey Nooner', img: 'avatar-s-8.png' },
                        { name: 'Julee Rossignol', img: 'avatar-s-10.png' },
                        { name: 'Jeffrey Gerondale', img: 'avatar-s-9.png' },
                    ],
                    commentbox: '',
                    usersCommented: [
                        
                    ]
                },
                {
                    author: 'Ömer Faruk DEMİRYÜREK',
                    time: 'Mon Dec 11 2011 07:35:05 GMT+3000 (GMT)',
                    isLiked: false,
                    text: 'Ceddan Bilişim Teknolojileri. uzman kadrosuyla, gerektiğinde 7 gün 24 saat yerinde destek dahil, birçok hizmeti dünya standartlarında vermektedir. Bunun yanı sıra kurumlara özel danışmanlık, bakım sözleşmeleri, teknik eğitimler, sistem kurulum, outsourcing, data recovery (veri kurtarma) gibi profesyonel hizmetleri de kendi bünyesinde siz değerli müşterilerine sunmaktadır.',
                    media: [{ img: '7-24-hizmet.png' }],
                    likes: 276,
                    comments: 105,
                    usersLiked: [
                        { name: 'Lai Lewandowski', img: 'avatar-s-6.png' },
                        { name: 'Elicia Rieske', img: 'avatar-s-7.png' },
                        { name: 'Darcey Nooner', img: 'avatar-s-8.png' },
                        { name: 'Julee Rossignol', img: 'avatar-s-10.png' },
                        { name: 'Jeffrey Gerondale', img: 'avatar-s-9.png' },
                    ],
                    commentbox: '',
                    usersCommented: [
                        
                    ]
                },
                {
                    author: 'Ömer Faruk DEMİRYÜREK',
                    time: 'Mon Dec 11 2011 08:017:05 GMT+3000 (GMT)',
                    isLiked: false,
                    text: 'Ceddan Bilişim Teknolojileri. Ulaşılır olmayı kendine hedef edinmiş ve her zaman müşterilerine doğru zamanda, doğru hizmeti sunmayı hedeflemiştir.',
                    media: [{ img: 'misyon-vizyon-degerler.jpg' }],
                    likes: 276,
                    comments: 105,
                    usersLiked: [
                        { name: 'Lai Lewandowski', img: 'avatar-s-6.png' },
                        { name: 'Elicia Rieske', img: 'avatar-s-7.png' },
                        { name: 'Darcey Nooner', img: 'avatar-s-8.png' },
                        { name: 'Julee Rossignol', img: 'avatar-s-10.png' },
                        { name: 'Jeffrey Gerondale', img: 'avatar-s-9.png' },
                    ],
                    commentbox: '',
                    usersCommented: [
                        
                    ]
                },
                {
                    author: 'Ömer Faruk DEMİRYÜREK',
                    time: 'Mon Dec 10 2011 12:05:05 GMT+0000 (GMT)',
                    isLiked: false,
                    text: 'Organizasyon ve iş süreçlerini yalın, bireysel ve kurumsal müşterilere olabildiğince az katmanla ve direkt erişmeye yönelik olarak yeniden düzenleyen Ceddan Bilişim Teknolojileri. böylelikle gereksiz tüm maliyetleri bertaraf ederek elde edebildiği rekabetçi fiyatlarını Türk BT pazarına yansıtmaktadır.',
                    media: [{ sources: [{ type: "video/mp4", src: "http://vjs.zencdn.net/v/oceans.mp4" }], poster: 'https://goo.gl/xcCsDd' }],
                    likes: 269,
                    comments: 98,
                    usersLiked: [
                        { name: 'Vennie Mostowy', img: 'avatar-s-5.png' },
                        { name: 'Elicia Rieske', img: 'avatar-s-7.png' },
                        { name: 'Julee Rossignol', img: 'avatar-s-10.png' },
                        { name: 'Darcey Nooner', img: 'avatar-s-8.png' },
                        { name: 'Elicia Rieske', img: 'avatar-s-7.png' },
                    ],
                    commentbox: '',
                    usersCommented: [
                        
                    ]
                }
            ],
            suggestedPages: [
                { img: 'page-09.jpg', title: 'Gimsa Marketler', type: 'Market Zinciri' },
                { img: 'page-08.jpg', title: 'Ankamar', type: 'Market' },
                { img: 'page-03.jpg', title: 'Özpa Market', type: 'Market' },
                { img: 'page-02.jpg', title: 'Elmacı Marketler', type: 'Market Zinciri' },
                { img: 'page-07.jpg', title: 'Sultanahmet Köftecisi', type: 'Restaurant' },
                { img: 'page-04.jpg', title: 'Hanzade Restoran', type: 'Restaurant' },
                { img: 'page-05.jpg', title: 'Kültür Bakanlığı', type: 'Kurum' },
                { img: 'page-01.jpg', title: 'Anadolu Medeniyetleri Müzesi', type: 'Müze' },
                { img: 'page-06.jpg', title: 'Lady Jasmine', type: 'E-Ticaret' },
                { img: 'page-08.jpg', title: 'Başkent Taşınmaz Değerleme A.Ş', type: 'Ekspertiz' },
                { img: 'page-08.jpg', title: 'Ato Ankara Ticaret Odası', type: 'Ticaret Odası' },
            ],
            polls: [{
                id: 59,
                title: 'Who is the best actor in Marvel Cinematic Universe?',
                options: [{
                        text: 'RDJ',
                        value: 'rdj',
                        voted: 58,
                        usersVoted: [
                            { name: 'Tonia Seabold', avatar: 'avatar-s-12.png' },
                            { name: 'Carissa Dolle', avatar: 'avatar-s-5.png' },
                            { name: 'Kelle Herrick', avatar: 'avatar-s-9.png' },
                            { name: 'Len Bregantini', avatar: 'avatar-s-10.png' },
                            { name: 'John Doe', avatar: 'avatar-s-11.png' },
                            { name: 'Tonia Seabold', avatar: 'avatar-s-12.png' },
                            { name: 'Dirk Fornili', avatar: 'avatar-s-2.png' },
                        ]
                    },
                    {
                        text: 'Chris Hemsworth',
                        value: 'chris hemsworth',
                        voted: 16,
                        usersVoted: [
                            { name: 'Liliana Pecor', avatar: 'avatar-s-6.png' },
                            { name: 'Kasandra Nalevanko', avatar: 'avatar-s-1.png' },
                        ]
                    },
                    {
                        text: 'mark ruffalo',
                        value: 'mark ruffalo',
                        voted: 8,
                        usersVoted: [
                            { name: 'Lorelei Lacsamana', avatar: 'avatar-s-4.png' },
                        ]
                    },
                    {
                        text: 'Chris Evans',
                        value: 'chris evans',
                        voted: 16,
                        usersVoted: [
                            { name: 'Jeanie Bulgrin', avatar: 'avatar-s-8.png' },
                            { name: 'Graig Muckey', avatar: 'avatar-s-3.png' },
                        ]
                    },
                ]

            }],
            twitterFeeds: [{
                    authorAvatar: 'avatar-s-12.png',
                    authorUsername: 'tiana59',
                    authorDisplayName: 'Tiana Vercetti',
                    content: 'I love cookie chupa chups sweet tart apple pie chocolate bar. Jelly-o oat cake chupa chups.',
                    tags: ['js', 'vuejs'],
                    time: 'Mon Dec 12 2018 07:46:05 GMT+0000 (GMT)',
                },
                {
                    authorAvatar: 'avatar-s-12.png',
                    authorUsername: 'tiana59',
                    authorDisplayName: 'Tiana Vercetti',
                    content: 'Carrot cake cake gummies I love I love tiramisu. Biscuit marzipan cookie lemon drops.',
                    tags: ['python'],
                    time: 'Mon Dec 11 2018 01:05:05 GMT+0000 (GMT)',
                },
                {
                    authorAvatar: 'avatar-s-12.png',
                    authorUsername: 'tiana59',
                    authorDisplayName: 'Tiana Vercetti',
                    content: 'I love cookie chupa chups sweet tart apple pie chocolate bar. Jelly-o oat cake chupa chups .',
                    tags: [],
                    time: 'Mon Dec 10 2018 03:33:05 GMT+0000 (GMT)',
                },
                {
                    authorAvatar: 'avatar-s-12.png',
                    authorUsername: 'tiana59',
                    authorDisplayName: 'Tiana Vercetti',
                    content: 'Muffin candy caramels. I love caramels tiramisu jelly. Pie I love wafer. Chocolate cake lollipop tootsie roll cake.',
                    tags: ['django', 'vuejs'],
                    time: 'Mon Dec 9 2018 08:47:05 GMT+0000 (GMT)',
                },

            ],
            wasSidebarOpen: null,
        }
    },
    computed: {
        mediaType() {
            return (media) => {
                if (media.img) {
                    const ext = media.img.split('.').pop();
                    if (this.mediaExtensions.img.includes(ext)) return 'image'
                } else if (media.sources && media.poster) {
                    // other validations
                    return 'video'
                }
            }
        },
        playerOptions() {
            return (media) => {
                return {
                    height: '360',
                    fluid: true,
                    autoplay: false,
                    muted: true,
                    language: 'en',
                    playbackRates: [0.7, 1.0, 1.5, 2.0],
                    sources: media.sources,
                    poster: media.poster,
                }
            }
        }
    },
    methods: {
        loadContent() {
            this.$vs.loading({
                background: this.backgroundLoading,
                color: this.colorLoading,
                container: "#button-load-more-posts",
                scale: 0.45
            })
            setTimeout(() => {
                this.$vs.loading.close("#button-load-more-posts > .con-vs-loading")
            }, 3000);
        },
    },
    components: {
        videoPlayer,
    },
    mounted() {
        this.wasSidebarOpen = this.$store.state.reduceButton;
        this.$store.commit('TOGGLE_REDUCE_BUTTON', true)
    },
    beforeDestroy() {
        if (!this.wasSidebarOpen) this.$store.commit('TOGGLE_REDUCE_BUTTON', false)
    }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuesax/pages/profile.scss";
</style>
